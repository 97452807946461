import React from "react";
import theme from "theme";
import { Theme, Link, Image, Box, Section, Text } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Top AI Tools
			</title>
			<meta name={"description"} content={"AI tool collections for professionals"} />
			<meta property={"og:title"} content={"Top AI Tools for marketers & content creators"} />
			<meta property={"og:description"} content={"AI tool collections for professionals"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/logo.png?v=2023-10-04T15:24:22.968Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/logo.png?v=2023-10-04T15:24:22.968Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon152.svg?v=2021-09-21T16:17:59.502Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6123949e957019001e284458/images/favicon270.svg?v=2021-09-21T16:18:07.731Z"} />
			<meta name={"msapplication-TileColor"} content={"#000848"} />
		</Helmet>
		<Image
			position="absolute"
			bottom="auto"
			md-width="600px"
			md-top="300px"
			lg-right="-200px"
			lg-top="0px"
			src="https://uploads.quarkly.io/6123949e957019001e284458/images/backgroung-hero.svg?v=2021-09-21T22:07:12.314Z"
			right="0px"
			top="130px"
			z-index="1"
		/>
		<Section background="linear-gradient(264.47deg, #000848 29.39%, #000848 93.49%)" padding="36px 0 36px 0" quarkly-title="Header">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="30%"
				display="flex"
				align-items="center"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				sm-width="50%"
			>
				<Image
					height="auto"
					src="https://uploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/logo.png?v=2023-10-04T15:24:22.968Z"
					width="100%"
					max-width="171px"
					srcSet="https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/logo.png?v=2023-10-04T15%3A24%3A22.968Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/logo.png?v=2023-10-04T15%3A24%3A22.968Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/logo.png?v=2023-10-04T15%3A24%3A22.968Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/logo.png?v=2023-10-04T15%3A24%3A22.968Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/logo.png?v=2023-10-04T15%3A24%3A22.968Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/logo.png?v=2023-10-04T15%3A24%3A22.968Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/logo.png?v=2023-10-04T15%3A24%3A22.968Z&quality=85&w=3200 3200w"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				empty-border-width="1px"
				width="70%"
				align-items="center"
				sm-width="50%"
				display="flex"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-style="solid"
				empty-border-color="LightGray"
			>
				<Components.QuarklycommunityKitMobileSidePanel md-z-index="9">
					<Override slot="Button Text" md-display="none" />
					<Override slot="Button Icon" md-color="--white" md-font="36px sans-serif" />
					<Override slot="Cross" md-color="--dark" md-font="36px sans-serif" />
					<Override slot="Wrapper" md-z-index="9" />
					<Override
						slot="Content"
						md-justify-content="flex-start"
						background="rgba(255, 255, 255, 0)"
						justify-content="center"
						align-items="center"
						padding="16px 0px 16px 16px"
						md-background="--color-light"
						md-z-index="9"
					/>
					<Override slot="Children" display="flex" align-items="center" justify-content="center" />
					<Box
						justify-content="flex-end"
						md-flex-direction="column"
						md-margin="40px 0px 13px 0px"
						md-font="--headline4"
						display="flex"
						align-items="center"
					>
						<Link
							color="--white"
							margin="0px 20px 0px 20px"
							md-margin="0px 0px 13px 0px"
							md-font="--lead"
							md-color="--indigo"
							href="#"
							font="normal 600 18px/1.5 --fontFamily-googleInter"
							text-decoration-line="initial"
						>
							Home
						</Link>
					</Box>
				</Components.QuarklycommunityKitMobileSidePanel>
			</Box>
		</Section>
		<Section background="linear-gradient(264.47deg, #000848 29.39%, #000848 93.49%)" padding="36px 0 80px 0" quarkly-title="HeroBlock">
			<Override
				slot="SectionContent"
				md-z-index="7"
				flex-direction="row"
				position="relative"
				z-index="9"
				md-flex-wrap="wrap"
			/>
			<Box
				empty-border-width="1px"
				align-items="flex-start"
				md-width="100%"
				display="flex"
				flex-direction="column"
				empty-border-color="LightGray"
				width="70%"
				justify-content="center"
				padding="0px 180px 0px 0px"
				lg-padding="0px 90px 0px 0px"
				empty-min-height="64px"
				empty-border-style="solid"
				empty-min-width="64px"
				md-padding="0px 0px 0px 0px"
			>
				<Text
					font="--base"
					color="--grey"
					text-transform="uppercase"
					lg-text-align="left"
					lg-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 8px 0px"
					lg-margin="0px 0px 8px 0px"
					sm-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
					letter-spacing="0.1em"
				>
					Page does not exist #error 404
				</Text>
				<Text
					letter-spacing="1%"
					lg-text-align="left"
					lg-font="normal 700 40px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 28px/1.2 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 16px 0px"
					font="--headline1"
					color="--white"
					sm-font="normal 500 30px/1.2 &quot;Inter&quot;, sans-serif"
				>
					Making is beatiful
				</Text>
				<Text
					opacity="0.7"
					font="--base"
					letter-spacing="0.05em"
					lg-text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Inter&quot;, sans-serif"
					md-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 0px 0px"
					color="--white"
				>
					Pardon the inconvenience. Our team is working hard to make this experience much more enjoyable.
				</Text>
			</Box>
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				display="flex"
				md-width="100%"
				empty-border-width="1px"
				empty-border-style="solid"
				width="30%"
				align-items="center"
				justify-content="flex-end"
				md-order="-1"
			>
				<Image
					lg-max-height="400px"
					width="100%"
					max-height="450px"
					md-margin="0px 0px 30px 0px"
					src="https://uploads.quarkly.io/6123949e957019001e284458/images/logos_foundation.svg?v=2021-10-05T09:15:03.700Z"
					lg-position="relative"
					lg-left="-5px"
					max-width="100%"
				/>
			</Box>
		</Section>
		<Section background="--color-indigo" padding="100px 0 0px 0" md-padding="40px 0 0px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-border-color="LightGray"
				flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				padding="16px 16px 16px 16px"
				md-width="100%"
				empty-border-style="solid"
				width="25%"
				display="flex"
			>
				<Image
					max-width="171px"
					src="https://uploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/favicon.png?v=2023-10-04T15:25:26.966Z"
					margin="0px 0px 24px 0px"
					srcSet="https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/favicon.png?v=2023-10-04T15%3A25%3A26.966Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/favicon.png?v=2023-10-04T15%3A25%3A26.966Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/favicon.png?v=2023-10-04T15%3A25%3A26.966Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/favicon.png?v=2023-10-04T15%3A25%3A26.966Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/favicon.png?v=2023-10-04T15%3A25%3A26.966Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/favicon.png?v=2023-10-04T15%3A25%3A26.966Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/651d5176c5fd0f0020b00bcd/images/favicon.png?v=2023-10-04T15%3A25%3A26.966Z&quality=85&w=3200 3200w"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Box
			empty-min-width="64px"
			empty-min-height="64px"
			empty-border-width="1px"
			empty-border-style="solid"
			empty-border-color="LightGray"
			background=",--color-indigo url(https://uploads.quarkly.io/6123949e957019001e284458/images/backfooter.svg?v=2021-08-24T19:40:13.860Z) 0% 0% /cover repeat scroll padding-box"
			min-height="220px"
			md-min-height="140px"
		/>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6123949e957019001e284456"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\na {\n  text-decoration: none;\n}"}
			</style>
			<script
				async={true}
				src={"https://www.googletagmanager.com/gtag/js?id=G-HZ33XRBXTY"}
				nomodule={true}
				place={"endOfHead"}
				rawKey={"651d7f3fd08b1e92cc52807d"}
			/>
			<script nomodule={true} place={"endOfHead"} rawKey={"651d7fc02f987da65d41b4b6"}>
				{"window.dataLayer = window.dataLayer || [];\n  function gtag(){dataLayer.push(arguments);}\n  gtag('js', new Date());\n\n  gtag('config', 'G-HZ33XRBXTY');"}
			</script>
		</RawHtml>
	</Theme>;
});